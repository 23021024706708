<template>
    <div class="projectList" v-on="$listeners">
        <div class="projectList-breadcrumb">
            <div class="box">
                <span style="cursor: pointer;" @click="toHome">首页</span><i class="el-icon-arrow-right back"></i>项目列表
            </div>
        </div>
        <img src="@/assets/project/banner.png" class="projectList-banner" alt="">
        <div class="projectList-search">
            <div class="projectList-search-item" v-for="item in searchData" :key="item.title">
                <span class="title">{{ item.title }}：</span>
                <ul>
                    <li :class="{ active: checkOptions.some(i => i.id === items.id) }" v-for="(items, key) in item.list"
                        :key="key" @click="search(item, items)">{{ items.labelName }}
                    </li>
                </ul>
            </div>
            <div class="projectList-search-item address">
                <span class="title">地区：</span>
                <div class="address-box">
                    <div class="top">
                        <span class="province" @click="addressCheck('province')">省</span>
                        <i  class="disable" style="margin:0 10px;">|</i>
                        <span class="city" @click="addressCheck('city')" :class="{disable: !formData.provinceCode}">市</span>
                        <span class="disable">（请先选择省，再选择市）</span>
                    </div>
                    <ul>
                        <li @click="addressSelect(item)" :class="{active: formData.provinceCode == item.id || formData.cityCode == item.id}" v-for="item in addressList" :key="item.id">{{ item.name }}</li>
                    </ul>
                </div>
            </div>
            <!-- <div class="projectList-search-item other" v-if="checkOptions.length">
                <span class="title">筛选条件：</span>
                <div class="other">
                    <el-tag disable-transitions closable @close="tagClose(tag)" v-for="tag in checkOptions"
                        :key="tag.title">{{ tag.labelName }}</el-tag>
                </div>
            </div> -->
            <div class="projectList-search-item other">
                <span class="title">其他：</span>
                <div class="other">
                    <!-- <el-cascader v-model="address" @change="addressChange" placeholder="所在地选择" :options="geoLogyOptions"
                        :props="{ label: 'name', value: 'id' }" clearable></el-cascader> -->
                    <el-input @change="resetRouter" style="width: 223px;" placeholder="项目名称"
                        v-model="formData.enterpriseName" clearable></el-input>
                    <el-input @change="resetRouter" style="width: 223px;" placeholder="企业联系人"
                        v-model="formData.enterpriseContact" clearable></el-input>
                    <!-- <el-input @change="resetRouter" style="width: 223px;" placeholder="企业联系方式"
                        v-model="formData.enterpriseContactWay" clearable></el-input> -->
                </div>
            </div>
        </div>
        <!-- <div class="projectList-tab">
            <el-radio-group class="tab" v-model="tab" @change="tabChange" style="margin-bottom: 30px;">
                <el-radio-button label="">默认</el-radio-button>
                <el-radio-button label="isHot">热门项目</el-radio-button>
                <el-radio-button label="isRecommend">推荐项目</el-radio-button>
            </el-radio-group>
        </div> -->
        <!-- <div class="projectList-total">
            共<i>{{ total }}</i>条
        </div> -->
        <div class="projectList-content" v-loading="loading" style="margin-top: 16px;">
            <div class="header">
                <span :class="{flex1: !item.width}" v-for="item in column" :key="item.title" :style="{textAlign: item.align, width: item.width}">{{ item.title }}</span>
            </div>
            <div class="column" v-for="(item) in projectList" :key="item.id">
                <div :class="['column-item',{flex1: !items.width, flexLeft: items.align == 'left'}]" v-for="items in column" :key="items.title" :style="{textAlign: items.align, width: items.width}">
                    <div class="enterpriseName" v-if="items.key == 'enterpriseName'">
                        <!-- <img class="cover" :src="item.cover" alt=""> -->
                        <el-image :src="item.cover" class="cover" fit="fill">
                            <div slot="error" class="error">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                        <p class="text">{{item.enterpriseName}}</p>
                    </div>
                    <div class="industryName" v-if="items.key == 'industryName'">
                        <span v-for="tag in (item.industryName || '').split(',').filter(i => i)" :key="tag">{{ tag }}</span>
                    </div>
                    <div class="businessIncomeName" v-if="items.key == 'businessIncomeName'">
                        {{item.businessIncomeName || '--'}}
                    </div>
                    <div class="netProfitName" v-if="items.key == 'netProfitName'">
                        {{item.netProfitName || '--'}}
                    </div>
                    <div class="address" v-if="items.key == ''">
                        {{item.provinceName || item.cityName ? `${item.provinceName && item.cityName ? `${item.provinceName}${item.cityName}` : item.provinceName || item.cityName}` : '--'}}
                    </div>
                    <div class="operate" v-if="items.key == 'operate'" @click="toDetail(item)">
                        <el-button type="primary" size="small">查看详情</el-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="projectList-content" v-loading="loading">

            <template v-if="projectList.length">
                <div class="content">
                    <contentItem
                        @click.native="$router.push({path: '/clientProjectDetail', query: { id: item.id, enterpriseName: item.enterpriseName }})"
                        :data="item" v-for="(item, index) in projectList" :key="index"></contentItem>
                </div>
            </template>
            <template v-else>
                <el-empty :image-size="200"></el-empty>
            </template>
        </div> -->
        <div class="projectList-pagination" v-if="projectList.length">
            <customePagination :current-page.sync="pageNum"  :total="total" @current-change="currentChange"></customePagination>
            <!-- <el-pagination :current-page.sync="pageNum" @current-change="currentChange" background
                layout="prev, pager, next" :total="total">
            </el-pagination> -->
        </div>
    </div>
</template>
<script>

import getLabelsMixins from '@/pages/layout/getLabelsMixins.js'
import customePagination from  '@/components/customePagination/index.vue'
// import { component } from 'vue/types/umd';
// import contentItem from '@/components/contentItem.vue'

export default {
    name: 'clientProjectList',
    data() {
        return {
            tab: '',
            loading: false,
            address: [],
            addressType: 'province',
            formData: {
                // 项目名称
                enterpriseName: '',
                // 企业联系人
                enterpriseContact: '',
                // 企业联系方式
                // enterpriseContactWay: '',
                // 所在地
                cityCode: '',
                provinceCode: '',
                // 行业关键词
                industry: '',
                // 最近财年营业总收入
                businessIncome: '',
                // 最近财年净利润
                netProfit: '',
                // 机构背景
                // institutionalBackgrounds: '',
                // 关注领域
                // focusAreas: '',
                // 偏好伦次
                // preferenceRound: '',
                // 热门项目
                isHot: undefined,
                // 推荐项目
                isRecommend: undefined
            },
            pageNum: 1,
            pageSize: 10,
            total: 0,
            projectStatus: 2,
            projectList: [],
            column: [
                {
                    title: '公司名称',
                    key: 'enterpriseName',
                    align: 'left',
                    width: '270px'
                },
                {
                    title: '关键词',
                    key: 'industryName',
                    align: 'center',
                    width: '300px'
                },
                {
                    title: '营收',
                    key: 'businessIncomeName',
                    align: 'center'
                },
                {
                    title: '净利润',
                    key: 'netProfitName',
                    align: 'center'
                },
                {
                    title: '所在地',
                    key: '',
                    align: 'left',
                    width: '160px'
                },
                {
                    title: '操作',
                    key: 'operate',
                    align: 'left',
                    width: '85px'
                }
            ]
        }
    },
    mixins: [getLabelsMixins],
    components: { customePagination },
    // components: { contentItem },
    computed: {
        selects() {
            return [1, 2, 3].map(i => this.selectArr.find(item => item.value === i))
        },
        searchData() {
            const { selectArr, options } = this
            const keys = Object.keys(options)
            return selectArr.filter(i => [1,2,3].includes(i.value)).map((item) => {
                const { key } = item
                const activeKey = keys.find(i => i.includes(key))
                return {
                    ...item,
                    list: options[activeKey]
                }
            })
        },
        checkOptions: {
            get() {
                const { searchData, $route: { query = {} } = {} } = this
                return Object.keys(query).filter(i => searchData.find(({ key }) => key === i)).map(key => {
                    const activeData = searchData.find(i => i.key === key)
                    return {
                        ...activeData,
                        ...(activeData.list.find(item => item.id === query[key]) || {})
                    }
                })
            },
            set(val) {
                return val
            }
        },
        addressList() {
            const { addressType, geoLogyOptions } = this
            if (addressType == 'province') {
                return  geoLogyOptions
            } else {
                return geoLogyOptions.find(i => i.id == this.formData.provinceCode).children
            }
        }
    },
    async mounted() {
        await this.$nextTick()
        await this.$nextTick()
        await this.$store.state.clientLoginRef.init({ showClose: false })
        const { query = {} } = this.$route
        Object.keys(this.formData).forEach(key => {
            this.formData[key] = query[key]
            if ((key === 'isHot' || key === 'isRecommend') && query[key]) {
                this.tab = key
            }
        })
        if (query.cityCode && query.provinceCode) {
            this.address = [query.provinceCode, query.cityCode]
        }
        this.$bus.$on('releaseSuccess', this.getList)
        this.$once('hook:beforeDestroy', () => {
            this.$bus.$off('releaseSuccess', this.getList);
        })
        this.getList()
    },
    methods: {
        async toDetail(data) {
            // await this.$store.state.clientLoginRef.init()
            const { id, enterpriseName, cover, industryName, businessIncomeName, netProfitName, provinceName, provinceCode, cityName, cityCode } = data 
            this.$router.push({
            path: '/clientProjectDetail',
                query: {
                    id,
                    enterpriseName,
                    cover,
                    industryName,
                    businessIncomeName,
                    netProfitName,
                    provinceName,
                    provinceCode,
                    cityName,
                    cityCode
                }
            })
        },
        toHome() {
            this.$router.push(
                {
                    path:'/'
                }
            )
        },
        addressSelect(val) {
            const { addressType } = this
            if (addressType == 'province' && this.formData.provinceCode != val.id) {
                this.formData.provinceCode = val.id
                this.formData.cityCode = ''
            } else if (addressType == 'city' && this.formData.cityCode != val.id) {
                this.formData.cityCode = val.id
            } else {
                this.formData.provinceCode = ''
                this.formData.cityCode = ''
            }
            this.resetRouter()
        },
        addressCheck(val) {
            if(!this.formData.provinceCode) return
            this.addressType = val
        },
        toProjectDetail(row){
            console.log(row)
        },
        currentChange(e) {
            this.pageNum = e
            this.getList()

        },
        resetRouter() {
            this.$router.push({
                path: '/clientProjectList',
                query: Object.keys(this.formData).reduce((p, n) => {
                    return {
                        ...p,
                        ...(this.formData[n] ? { [n]: this.formData[n] } : {})
                    }
                }, {})
            })
        },
        tabChange() {
            const { tab } = this
            this.formData.isHot = undefined
            this.formData.isRecommend = undefined
            if (tab) {
                this.formData[this.tab] = 1
            }
            this.resetRouter()
        },
        tagClose(data) {
            this.formData[data.key] = ''
            this.resetRouter()
        },
        search(item, items) {
            if (item && this.formData[item.key] != items.id) {
                this.formData[item.key] = items.id
                this.checkOptions = this.checkOptions.filter(i => i.key !== item.key)
            } else {
                this.formData[item.key] = ''
            }
            this.resetRouter()
        },
        addressChange([provinceCode = '', cityCode = '']) {
            this.formData.cityCode = cityCode
            this.formData.provinceCode = provinceCode
            this.resetRouter()
        },
        getList() {
            const { pageSize, projectStatus, tab } = this
            this.loading = true
            const params = {
                ...this.formData,
                pageNum: this.pageNum,
                pageSize,
                projectStatus
            }
            if (params.industry) params.industryList = [params.industry]
            if (params.enterpriseName) params.projectName = params.enterpriseName
            // if (params.businessIncome) params.businessIncomeList = params.businessIncome
            // if (params.netProfit) params.netProfitList = params.netProfit
            delete params.industry
            // delete params.businessIncome
            // delete params.netProfit
            if (tab) {
                params[tab] = 1
            }
            this.$http.Post(this.$api.getProjectList, params).then(({ data: { total, records } = {} }) => {
                this.total = Number(total || '0')
                this.projectList = records
            }).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style lang="less" scoped>
.projectList {
    &-breadcrumb {
        // background-color: #409EFF;
        font-size: 14px;
        margin-top: 21px;

        .box {
            width: 1125px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            color: #2E3742;
            span{
                color: #95A1AF;
            }
            i {
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }

    &-total{
        line-height: 44px;
        width: 1125px;
        margin: 0 auto;
        padding: 0 30px;
        box-sizing: border-box;
        color: #2e3742;
        font-size: 14px;
        i{
            color: #1C68FF;
        }
    }
    
    &-banner{
        width: 1125px;
        display: block;
        margin: 16px auto;
    }

    &-search {
        width: 1125px;
        margin: 16px auto 0;
        background-color: #fff;
        padding: 30px;
        border-radius: 8px;
        box-sizing: border-box;

        &-item {
            min-height: 45px;
            display: flex;
            &.other{
                .title{
                    line-height: 45px;
                }
            }
            &.address{
                .title{
                    line-height: 37px;
                }
                .address-box{
                    font-size: 14px;
                    flex: 1;
                    .top{
                        line-height: 36px;
                        color: #2E3742;
                        padding: 0 25px;
                        .disable{
                            color: #95a1af;
                        }
                        .province,.city{
                            cursor: pointer;
                        }
                    }
                }
            }

            .title {
                width: 100px;
                line-height: 26px;
                color: #2E3742;
                text-align: right;
                margin-right: 8px;
                font-size: 16px;
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                flex: 1;
                gap: 10px 10px;

                li {
                    padding:4px 10px;
                    border-radius: 4px;
                    cursor: pointer;
                    color: #2E3742;
                    font-size: 14px;

                    &.active {
                        color: #fff;
                        background-color: #1C68FF;
                    }
                }
            }

            .el-tag+.el-tag {
                margin-left: 20px;
            }

            .el-input {
                margin-left: 20px;
            }

            .other {
                display: flex;
                align-items: center;
            }
        }

        &-item+&-item {
            // border-top: 1px solid #ddd;
            margin-top: 24px;
        }
    }

    &-tab {
        width: 1125px;
        margin: 20px auto !important;

        .tab {
            margin: 0 !important;
        }
    }

    &-content {
        width: 1125px;
        margin: 0 auto;
        background-color: #fff;
        box-sizing: border-box;
        padding: 40px 20px;
        border-radius: 8px;
        // .content {
        //     width: 100%;
        //     display: flex;
        //     flex-wrap: wrap;
        //     gap: 24px 37px;
        //     padding-top: 20px;
        // }
        .header{
            height: 40px;
            background-color: #F2F4F7;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #2e3742;
            font-size: 14px;
            padding: 0 17px;
        }
        .flex1{
            flex: 1;
            width: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .column{
            display: flex;
            justify-content: center;
            padding: 20px 12px;
            // cursor: pointer;
            &-item{
                display: flex;
                align-items: center;
                justify-content: center;
                &.flexLeft{
                    justify-content: start;
                }
                &:has(.enterpriseName){
                    justify-content: start;
                }
            }
            .enterpriseName{
                display: flex;
                align-items: center;
                .cover{
                    width: 90px;
                    height: 90px;
                    border-radius: 8px;
                    margin-right: 20px;
                    /deep/ .error{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 30px;
                        background: #f5f7fa;
                        color: #909399;
                    }
                }
                .text{
                    flex: 1;
                    color: #2e3742;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 1.5;
                }
            }
            .industryName{
                display: flex;
                justify-content: center;
                align-items: center;
                span{
                    background-color: rgba(28, 104, 255, 0.05);
                    height: 20px;
                    padding: 0 6px;
                    border: 1px solid rgba(28, 104, 255);
                    border-radius: 5px;
                    font-size: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #1C68FF;
                    font-weight: 500;
                    & +span{
                        margin-left: 12px;
                    }
                }
            }
            .businessIncomeName{
                color: #2e3742;
                font-size: 14px;
                font-weight: 500;
            }
            .netProfitName{
                color: #2e3742;
                font-size: 14px;
                font-weight: 500;
            }
            .address{
                color: #2e3742;
                font-size: 14px;
                font-weight: 500;
            }
            .operate{
                .el-button{
                    background-color: #1C68FF;
                }
            }
        }
    }

    &-pagination {
        width: 1125px;
        margin: 20px auto;
        text-align: center;

        .el-pagination {
            display: inline-block;
            
        }
        /deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
            background-color: #1C68FF;
        }
    }
}
</style>